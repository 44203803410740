import Breadcrumbs from "@components/Breadcrumbs/Breadcrumbs"
import Layout from "@components/Layout/Layout"
import StaticData from "@components/StaticData/StaticData"
import Seo from "@components/seo"
import "@styles/main.scss"
import { graphql } from "gatsby"
import React from "react"

const StaticTemplate = ({ data }) => {
  const pageData = data?.strapiPage
  const menuData = pageData?.menu
  const banner = pageData?.banner

  return (
    <Layout pageData={pageData}>
      <Breadcrumbs menuData={menuData} />
      <StaticData
        title={banner?.title}
        description={banner?.description?.data?.description}
      />
    </Layout>
  )
}

export default StaticTemplate

export const Head = ({ data }) => {
  const pageData = data?.strapiPage

  return (
    <Seo
      title={pageData?.seo?.metaTitle}
      description={pageData?.seo?.metaDescription}
      customCanonical={pageData?.seo?.canonicalURL}
    />
  )
}

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...pageFragment
    }
  }
`
